.login-form {
    max-width: 500px;
    margin: 50px auto;
    padding: 30px;
    border-radius: 8px;
    background: linear-gradient(135deg, #f8f9fa, #ffffff);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .login-title {
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
  }
  
  .login-input {
    transition: all 0.4s ease-in-out;
  }
  
  .login-input .MuiOutlinedInput-notchedOutline {
    border-color: #ccc;
    transition: border-color 0.4s ease, box-shadow 0.4s ease;
  }
  
  .login-input:hover .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
    background: linear-gradient(90deg, #007bff, #0056b3);
    -webkit-background-clip: text;
    color: transparent;
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.5);
  }
  
  .login-input:focus-within .MuiOutlinedInput-notchedOutline {
    border: 2px solid #0056b3;
    box-shadow: 0 4px 15px rgba(0, 86, 179, 0.8);
  }
  
  .login-button {
    margin-top: 20px;
    padding: 10px;
    background: linear-gradient(90deg, #007bff, #0056b3);
    color: #fff;
    transition: background 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  .login-button:hover {
    background: linear-gradient(90deg, #0056b3, #007bff);
    transform: translateY(-2px);
    transition: all 0.4s ease-in-out;
  }
  
  .social-login-text {
    margin: 20px 0 10px;
    color: #666;
  }
  
  .social-login-button {
    margin: 10px 0;
    padding: 10px;
    font-weight: bold;
    color: #fff;
  }
  
  .social-login-button.google {
    background: linear-gradient(90deg, #db4437, #c23321);
  }
  
  .social-login-button.facebook {
    background: linear-gradient(90deg, #3b5998, #2d4373);
  }
  
  .social-login-button:hover {
    opacity: 0.9;
    transition: all 0.4s ease-in-out;
  }
  