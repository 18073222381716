/* Add spacing between login and sign-up buttons in desktop view */
.navbar-buttons .btn-login {
    margin-right: 10px; /* Adjust the margin to control spacing */
  }
  
  /* Remove outline/border from the btn-login */
  .btn-login {
    outline: none;
    border: none;
  }

  .btn-login:hover{
    color: #777;
   
  }
  
  /* Adjust padding for both buttons */
  .btn-capsule,
  .btn-capsule-small {
    padding-top: 5px; /* Adjust top padding */
    padding-bottom: 5px; /* Adjust bottom padding */
  }
  /* Ensure these styles are included in your header.css */
/* Ensure these styles are included in your header.css */
/* Styles for the modal backdrop */
.modal-backdrop {
    position: fixed; /* Fixed position to cover the entire viewport */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    z-index: 1000; /* Ensure it appears above all content */
  }
  
  /* Styles for the modal itself */
  .modal {
    position: fixed; /* Fixed position */
    top: 50%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust to center */
    background-color: white; /* White background */
    padding: 20px; /* Padding for content */
    border-radius: 8px; /* Rounded corners */
    z-index: 1001; /* Ensure it appears above the backdrop */
    width: 400px; /* Set a fixed width for the modal */
    max-width: 90%; /* Responsive max width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for depth */
  }
  
  