.how-it-works-container {
  text-align: center;
  background-color: black; /* Background color */
  padding: 40px 20px; /* Outside padding */
}

.how-it-works-container h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  color: white ;
  font-weight: bold;
}

.steps {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.step-item {
  width: 30%; /* Controls width on larger screens */
  margin: 20px 0; /* Outside margin */
  padding: 20px; /* Inside padding */
  text-align: center;
  background-color: #fff; /* Optional background for each item */
  border-radius: 10px;
}

.icon-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  margin: 0 auto 15px auto;
}

.step-item h3 {
  font-size: 1.2rem;
  margin: 15px 0;
  color: #333;
  font-weight: bold;
}

.step-item p {
  font-size: 0.95rem;
  color: #000;
  margin: 0;
  font-weight: 600;
}

@media (max-width: 768px) {
  .steps {
    flex-direction: column;
    align-items: center;
  }

  .step-item {
    width: 90%; /* Full width on mobile */
  }
}
