/* ServiceProviders Container */
.container-fluid.service-slider {
    padding: 20px;
}

/* Slider Wrapper */
.slider-wrapper .slick-slide {
    padding: 0 10px;
}

.slider-wrapper .slick-dots {
    bottom: -20px;
}

/* Card Wrapper and Card Styles */
.card-wrapper {
    display: flex;
    justify-content: center;
    padding: 10px;
}

.card {
    max-width: 400px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    text-align: left;
}

/* Image Styles */
.universal-img {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-bottom: 2px solid #f0f0f0;
    border-radius: 8px 8px 0 0;
}

/* Card Body */
.card-body {
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Card Header with Title and Rating */
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 8px;
}

.card-title {
    font-size: 15px;
    font-weight: bold;
    text-transform: uppercase;
    margin: 0;
}

.star-rating {
    font-size: 16px;
    color: #ffc107; /* Gold color for stars */
}

/* Location and Duration Row */
.location-duration-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
    align-items: flex-start;
}

.location-text {
    font-size: 14px;
    color: #333;
    max-width: 70%;
    margin: 0;
    white-space: normal;
    overflow-wrap: break-word;
}

.time-text {
    font-size: 14px;
    color: #007bff;
    font-weight: bold;
    margin: 0;
    text-align: right;
    flex-shrink: 0;
}

/* Service Type and Distance Row */
.service-distance-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
    align-items: flex-start;
}

.service-text, .distance-text {
    font-size: 14px;
    color: #333;
    margin: 0;
}

/* Button Group Styling */
.button-group {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 10px;
    width: 100%;
}

.button-group .btn {
    font-size: 14px;
    padding: 8px 12px;
    border-radius: 4px;
}

.btn-primary {
    background-color: #007bff;
    border: none;
    color: #fff;
}

.btn-secondary {
    background-color: #6c757d;
    border: none;
    color: #fff;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .card {
        max-width: 100%;
    }

    .universal-img {
        height: 150px;
    }

    .slider-wrapper .slick-slide {
        padding: 0 5px;
    }
}
/* Popup Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup Content */
.popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 350px; /* Constrain width for better visibility */
    max-width: 90vw; /* Responsive width adjustment */
    max-height: 70vh; /* Constrain height to avoid overflow */
    overflow-y: auto; /* Enable vertical scroll for long content */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

/* Popup Title */
.popup-content h3 {
    margin-bottom: 15px;
    text-align: center;
}

/* Slots List - Vertical Display */
.slots-container {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Add spacing between slots */
}

/* Slot Button */
.slot-btn {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    text-align: center;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.slot-btn:hover {
    background-color: #0056b3; /* Darker shade on hover */
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    color: #333;
    font-size: 18px;
    border: none;
    cursor: pointer;
}

 /* Close Button Style */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    color: #333;
    font-size: 20px;
    border: none;
    cursor: pointer;
}
 
/* Popup Overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Popup Content */
.popup-content {
    position: relative; /* Ensures close button is positioned relative to this container */
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 350px;
    max-width: 90vw;
    max-height: 70vh;
    overflow-y: auto;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

/* Close Button */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    color: #333;
    font-size: 20px;
    border: none;
    cursor: pointer;
}

/* Slots Container */
.slots-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
}
