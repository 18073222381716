/* Full width on mobile */
.responsive-input {
    width: 100%;
    height: 50px;
    padding: 10px;
    border-radius: 12px;
    margin-bottom: 20px;
  }
  
  /* Adjust width for desktop view */
  @media (min-width: 768px) {
    .responsive-input {
      width: 75%;
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    max-width: 500px;
    width: 90%;
  }
  
  .car-model-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .car-model-item {
    width: 100px;
    cursor: pointer;
    text-align: center;
  }
  
  .car-model-image {
    width: 100%;
    height: auto;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  
  .btn-close {
    margin-top: 10px;
    background: #444;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .car-models-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    text-align: center;
  }
  
  .models-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
  }
  
  .model-item {
    text-align: center;
  }
  
  .model-image {
    max-width: 100%;
    height: auto;
    border-radius: 5px;
    margin-bottom: 5px;
  }
    
  .popup {
    position: fixed;
    top: 50%;
    left: 25%; /* Adjusted for mobile view */
    transform: translate(-50%, -30%);
    background: white;
    border-radius: 10px;
    padding: 20px;
    z-index: 1000;
    width: 80%;
    max-width: 600px;
    text-align: center;
    border: 1px solid navy;
    overflow-y: auto; /* Enables vertical scrolling */
    max-height: 81vh; /* Limits height to viewport height */
  }
  
  /* Add media query for mobile adjustments */
  @media (max-width: 768px) {
    .popup {
      left: 50%; /* Centered horizontally on mobile */
      transform: translate(-50%, -50%); /* Fully center in mobile */
    }
  }
  
  
  .popup-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .brand-item, .model-item {
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    padding: 10px;
    box-shadow:rgba(0, 0, 0, 0.2);
  }
  
  .brand-item img, .model-item img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 8px;
   
  }
  
  .brand-item:hover, .model-item:hover {
    transform: scale(1.1);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  }
  .popup-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    color: #333;
    outline: none;
  }
  
  .popup-close-btn:hover {
    color: #ff0000;
  }
  .brand-search-box {
    width: 100%; /* Adjust to fit within the popup */
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  