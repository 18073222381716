.footer-main-block {
    padding: 40px 0;
    background-color: #000;
    color: white;
}

.footer-title {
    color: #ffffff;
    font-weight: bold;
}

.social-icons {
    display: flex; /* Flex layout for alignment */
    gap: 5px; /* Space between icons */
    justify-content: flex-start; /* Align icons to the left */
    padding: 0 0; /* Add default padding */
    background-color: transparent !important;
    margin-left: -100px;
    font-size: 20px;
}

.social-icons .social-icon {
    font-size: 2.5rem;
    text-decoration: none;
    color: white; /* Ensure icon color */
    background-color: transparent !important;
}

.language-options {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    padding: 10px 15px;
}

.language-options i {
    margin-right: 5px;
}

.container-fluid {
    padding: 0 5%;
}

.footer-links {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .social-icons {
        margin-left: 5px !important; /* Left padding on mobile */
        margin-right: 5px !important; /* Right padding on mobile */
        background-color: transparent !important;
       
        
    }

    /* Applies padding to each icon directly */
    .social-icons .social-icon {
        padding: 0 0; /* Reduced space between icons */
        margin: 0; /* Ensure no additional margin */
        background-color: transparent !important;
    }

    .language-options {
        padding: 10px 15px;
        margin-left: -12px;
    }

    .footer-links {
        flex-direction: row;
        justify-content: flex-start;
        margin-top: 10px;
    }

    /* Ensure individual icon items don’t shrink */
    .social-icons li {
        flex: 0 0 auto;
        background-color: transparent !important;
    }
}

.social-icons {
    display: flex;
    gap: 5px; /* Adjust space between icons */
    justify-content: flex-start;
}

.social-container {
    padding: 0 0; /* Default padding for desktop */
}

@media (max-width: 768px) {
    .social-container {
        padding-left: 1px; /* Left padding for mobile */
        padding-right: 1px; /* Right padding for mobile */
    }

    .language-options {
        padding: 10px 15px;
        margin-left: -12px;
    }

    .contain{
        margin-left: 0;
        margin-right: 0;
    }
}
/* General Styles */
.social-icons {
    justify-content: space-around; /* Ensures even spacing across the container */
  }
  
  /* Mobile view: make icons occupy the full width with sufficient spacing */
  @media (max-width: 768px) {
    .social-icons li {
      flex: 1; /* Each icon takes equal width */
      text-align: left; /* Center-aligns each icon within its container */
      margin-left: 0;
    }
    .mleft{
        margin-left: 20px !important;
      }
  }
  
  /* Desktop view: wider spacing between icons */
  @media (min-width: 769px) {
    .social-icons {
      gap: 50px; /* Adds wider spacing between icons for desktop */
      margin-left: 0;
      text-align: left;
     
    }
  }
  