.suggestion-item {
    width: 200px; /* Fixed width */
    height: 250px; /* Fixed height */
    box-sizing: border-box;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px; /* Spacing between items */
    position: relative; /* Enable positioning within the box */
}

.suggestion-icon {
    width: 60px; /* Reduced width */
    height: 60px; /* Reduced height */
    position: absolute;
    top: 30px; /* Adjusted for padding */
    right: 30px; /* Adjusted for padding */
    padding: 8px; /* Inner padding for icon */
   
    border-radius: 50%; /* Circular background */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Optional shadow */
}

.suggestion-icon img {
    width: 100%;
    height: 100%;
    border-radius: 50%; /* Matches circular background */
}

.suggestion-content {
    flex-grow: 1;
    padding-right: 90px; /* Offset to prevent overlap with icon */
}

.details-button {
    margin-top: 10px;
}

@media (max-width: 768px) {
    .suggestion-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: center; /* Center items in mobile view */
    }

    .suggestion-item {
        width: 280px; /* Adjusted width for smaller screens */
        height: 200px; /* Consistent height */
        margin: 10px; /* Maintain spacing in mobile view */
    }
}
